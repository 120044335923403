<template>
  <div>
    <v-snackbar v-model="snackbar" top color="green">{{
      snackbarText
    }}</v-snackbar>
    <v-snackbar v-model="errorSnackbar" top color="red" outlined>{{
      errorSnackbarText
    }}</v-snackbar>
    <div>
      <Nav />
      <v-container>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="8" lg="6" xl="5">
            <div>
              <v-progress-circular
                v-if="pendingLoading"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <PendingVerifications
                v-if="pendings.length > 0"
                :pendings="pendings"
              />
              <PendingWaitlists
                v-if="pendingWaitlists.length > 0"
                :pendingWaitlists="pendingWaitlists"
              />
              <PendingCashPaymentRequests
                v-if="pendingCashRequests.length > 0"
                :pendings="pendingCashRequests"
              />
              <PendingPrivateTransfers
                v-if="pendingPrivateTransfers.length > 0"
                :pendings="pendingPrivateTransfers"
              />
            </div>
            <v-tabs show-arrows center-active v-model="tab">
              <v-tab>Trips</v-tab>
              <v-tab>Bookings</v-tab>
              <v-tab>Waitlists</v-tab>
              <v-tab>Private Transfers</v-tab>
              <v-tab>Boats</v-tab>
              <v-tab>Providers</v-tab>
              <v-tab>Users</v-tab>
              <v-tab>Locations</v-tab>
              <v-tab>Reports</v-tab>
              <v-tab>Config</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item key="trips">
                <div v-if="selectedTrip">
                  <v-card>
                    <v-row
                      dense
                      style="
                        margin-top: 20px;
                        padding-left: 16px;
                        padding-right: 16px;
                      "
                    >
                      <v-col cols="12">
                        <v-card-text style="padding: 0px">
                          <v-btn
                            outlined
                            color="primary"
                            block
                            large
                            @click="
                              selectedTrip = null;
                              bookings = [];
                            "
                          >
                            <v-icon class="mr-2"
                              >mdi-arrow-left-bold-circle-outline</v-icon
                            >Back
                          </v-btn>
                        </v-card-text>
                      </v-col>
                      <v-col sm="6" cols="12">
                        <RescheduleTrip
                          :trip="selectedTrip"
                          :getTrips="getTrips"
                          :message="message"
                          :errorMessage="errorMessage"
                          :updateDate="updateDate"
                        />
                      </v-col>
                      <v-col sm="6" cols="12">
                        <v-dialog v-model="confirmDialog" max-width="400">
                          <template v-slot:activator="{ on, attrs }">
                            <v-card-text style="padding: 0px">
                              <v-btn
                                color="red"
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                :loading="deletingTrip"
                                block
                                large
                              >
                                <v-icon class="mr-2">mdi-delete-outline</v-icon
                                >Delete Trip
                              </v-btn>
                            </v-card-text>
                          </template>
                          <v-card>
                            <v-card-text
                              style="padding-bottom: 0px; padding-top: 20px"
                              >Are you sure to delete this trip?
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text @click="deleteTrip()"
                                >Delete</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-card-subtitle
                      style="padding-bottom: 0px"
                      class="subtitle-1"
                    >
                      Trip #{{ selectedTrip.id }}
                    </v-card-subtitle>
                    <v-card-title
                      class="mb-2"
                      style="align-items: center; padding-top: 0px"
                    >
                      <span style="margin-right: 10px">{{
                        toFromString(selectedTrip)
                      }}</span>
                      <span style="padding-top: 3px" class="text-subtitle-1">{{
                        getDatePortion(selectedTrip.fromTime)
                      }}</span>
                    </v-card-title>
                    <v-card-subtitle>
                      <div>
                        <v-icon class="mb-1">mdi-ferry</v-icon>
                        <span class="subtitle-1">{{
                          selectedTrip.boat.name
                        }}</span>
                        <ChangeTripBoat
                          :trip="selectedTrip"
                          :getTrips="getTrips"
                          :message="message"
                          :errorMessage="errorMessage"
                          :boats="boats"
                          :seatConfig="
                            configEnabled('Enable seat selection feature')
                          "
                        />
                        <v-icon class="mb-1">mdi-account-cowboy-hat</v-icon>
                        <span class="subtitle-1">{{
                          selectedTrip.boat.boatStaff
                            .map((bs) => bs.user.username)
                            .join(", ")
                        }}</span>
                      </div>
                      <v-icon dense class="mb-1">mdi-map-marker</v-icon>
                      <span class="subtitle-1">{{
                        selectedTrip.fromLocation.name
                      }}</span>
                      <v-icon class="mb-1">mdi-ray-start-arrow</v-icon>
                      <span class="subtitle-1">{{
                        selectedTrip.toLocation.name
                      }}</span>
                      <div style="display: flex">
                        <div>
                          <div v-for="point in pickupPoints">
                            {{ point.count }} from {{ point.name }}
                          </div>
                        </div>
                        <div style="margin-left: 1rem">
                          <div v-for="point in dropoffPoints">
                            {{ point.count }} to {{ point.name }}
                          </div>
                        </div>
                      </div>
                      <div>
                        <v-icon dense>mdi-account</v-icon>
                        {{ selectedTrip.booked }}/{{ selectedTrip.capacity }}
                        seats booked
                        <ViewLayout
                          v-if="configEnabled('Enable seat selection feature')"
                          :trip="selectedTrip"
                          :errorMessage="errorMessage"
                        />
                        <SeatBreakdown :bookings="selectedTrip.bookings" />
                      </div>
                      <div>
                        <v-icon dense>mdi-currency-usd</v-icon>
                        {{ decimalCurrency(selectedTrip.laariPerSeat) }} MVR or
                        {{ decimalCurrency(selectedTrip.centPerSeat) }} USD per
                        seat
                        <EditTripPricing
                          :trip="selectedTrip"
                          :getTrips="getTrips"
                          :message="message"
                          :errorMessage="errorMessage"
                        />
                        <!-- <PaymentDetails
                          :trip="selectedTrip"
                          :errorMessage="errorMessage"
                          :decimalCurrency="decimalCurrency"
                          :bookings="bookings"
                        /> -->
                        <a
                          :href="`/trip-payments/${selectedTrip.id}`"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn
                            color="primary"
                            icon
                            title="View trip payment report"
                          >
                            <v-icon>mdi-printer</v-icon>
                          </v-btn>
                        </a>
                        <v-btn
                          color="primary"
                          icon
                          title="Download trip payment report"
                          @click="downloadCsv"
                        >
                          <v-icon>mdi-table-arrow-down</v-icon>
                        </v-btn>
                      </div>
                      <div
                        v-if="
                          selectedTrip.laariPerSeat !==
                            selectedTrip.laariPerSeatLocal ||
                          selectedTrip.centPerSeat !==
                            selectedTrip.centPerSeatLocal
                        "
                      >
                        <v-icon dense>mdi-currency-usd</v-icon>
                        {{ decimalCurrency(selectedTrip.laariPerSeatLocal) }}
                        MVR or
                        {{ decimalCurrency(selectedTrip.centPerSeatLocal) }} USD
                        per seat for locals
                      </div>
                      <div>
                        <FuelConsumption
                          :trip="selectedTrip"
                          :message="message"
                          :errorMessage="errorMessage"
                          :getTrips="getTrips"
                        />
                      </div>
                      <div>
                        <TripRemarks
                          :trip="selectedTrip"
                          :message="message"
                          :errorMessage="errorMessage"
                          :getTrips="getTrips"
                        />
                      </div>
                      <div style="margin-top: 10px">
                        <v-chip
                          v-if="selectedTrip.isPrivate"
                          class="ma-2"
                          color="teal lighten-2"
                          >Private Transfer
                        </v-chip>
                        <v-chip
                          v-if="selectedTrip.departed && !selectedTrip.ended"
                          class="ma-2"
                          color="blue"
                          >Departed at
                          {{ formatTime(selectedTrip.departed_at) }}</v-chip
                        >
                        <v-chip
                          v-if="selectedTrip.departed && selectedTrip.ended"
                          class="ma-2"
                          color="green"
                          >Ended at
                          {{ formatTime(selectedTrip.ended_at) }}</v-chip
                        >
                      </div>
                    </v-card-subtitle>
                    <v-card-text>
                      <v-progress-linear
                        v-if="bookingsLoading"
                        indeterminate
                        color="primary"
                      ></v-progress-linear>
                      <span v-if="bookings.length === 0 && !bookingsLoading"
                        >No bookings</span
                      >
                      <v-text-field
                        style="margin-bottom: 0.5rem"
                        v-model="bookingSearch"
                        placeholder="Search bookings"
                        outlined
                        hide-details
                        dense
                        clearable
                      ></v-text-field>
                      <BookingDialog
                        v-for="booking in filterBookings(bookings)"
                        :key="booking.id"
                        :bookingProp="booking"
                        :message="message"
                        :errorMessage="errorMessage"
                        :getBookings="getBookings"
                        :getTrips="getTrips"
                        :seatConfig="
                          configEnabled('Enable seat selection feature')
                        "
                      />
                    </v-card-text>
                  </v-card>
                </div>
                <div v-else>
                  <v-card>
                    <v-card-text>
                      <v-dialog
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div style="display: flex; align-items: center">
                            <v-btn icon @click="changeDate(-1)">
                              <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-text-field
                              outlined
                              :value="computedDate"
                              label="Date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                            ></v-text-field>
                            <v-btn icon @click="changeDate(1)">
                              <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                          </div>
                        </template>

                        <v-date-picker
                          v-model="date"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-dialog>
                      <div style="margin-top: 1rem">
                        <AddTrip
                          :message="message"
                          :errorMessage="errorMessage"
                          :date="date"
                          :computedDate="computedDate"
                          :boats="boats"
                          :locations="locations"
                          :getTrips="getTrips"
                        />
                      </div>
                      <v-progress-linear
                        v-if="tripsLoading"
                        indeterminate
                        color="primary"
                      ></v-progress-linear>
                      <div v-if="trips.length === 0">No trips for the day.</div>
                      <v-card
                        flat
                        color="grey lighten-4"
                        class="mb-2"
                        ripple
                        v-for="trip in trips"
                        :key="trip.id"
                        @click="selectedTrip = trip"
                      >
                        <v-card-text class="pt-4">
                          <v-row align="center">
                            <v-col style="padding-bottom: 0px">
                              <div>
                                <v-card-subtitle
                                  style="padding: 0px"
                                  class="subtitle-1"
                                >
                                  Trip #{{ trip.id }}
                                </v-card-subtitle>
                              </div>
                              <span class="title">{{
                                toFromString(trip)
                              }}</span>
                            </v-col>
                            <v-col class="shrink" style="padding: 0px">
                              <v-chip
                                v-if="trip.isPrivate"
                                class="ma-2"
                                color="teal lighten-2"
                                >Private Transfer
                              </v-chip>
                              <v-chip
                                v-if="trip.departed && !trip.ended"
                                class="ma-2"
                                color="blue"
                                >Departed</v-chip
                              >
                              <v-chip
                                v-if="trip.departed && trip.ended"
                                class="ma-2"
                                color="green"
                                >Ended</v-chip
                              >
                            </v-col>
                          </v-row>
                          <v-row style="margin-top: 0px">
                            <v-col class="mt-1">
                              <div>
                                <v-icon class="mb-1">mdi-ferry</v-icon>
                                <span class="subtitle-1">{{
                                  trip.boat.name
                                }}</span>
                                <v-icon class="mb-1"
                                  >mdi-account-cowboy-hat</v-icon
                                >
                                <span class="subtitle-1">{{
                                  trip.boat.boatStaff
                                    .map((bs) => bs.user.username)
                                    .join(", ")
                                }}</span>
                              </div>
                              <span class="subtitle-1">{{
                                trip.fromLocation.name
                              }}</span>
                              <v-icon class="mb-1">mdi-ray-start-arrow</v-icon>
                              <span class="subtitle-1">{{
                                trip.toLocation.name
                              }}</span>
                            </v-col>
                            <v-col class="shrink">
                              <v-btn text disabled>
                                <v-icon dense>mdi-account</v-icon>
                                {{ trip.booked }}/{{ trip.capacity }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </div>
              </v-tab-item>
              <v-tab-item key="bookings">
                <Bookings
                  :message="message"
                  :errorMessage="errorMessage"
                  :getImageUrl="getImageUrl"
                  :getBookings="getBookings"
                  :getTrips="getTrips"
                />
              </v-tab-item>
              <v-tab-item key="waitlists">
                <div>
                  <v-card>
                    <v-card-text>
                      <v-dialog
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            :value="computedDate"
                            label="Date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-dialog>
                      <v-progress-linear
                        v-if="waitlistsLoading"
                        indeterminate
                        color="primary"
                      ></v-progress-linear>
                      <div v-if="waitlists.length === 0">
                        No waitlists for the day.
                      </div>
                      <div v-for="(waitlistFromTo, i) in waitlists" :key="i">
                        <v-divider
                          v-if="i !== 0"
                          style="margin-top: 20px; margin-bottom: 20px"
                        ></v-divider>
                        <span class="subtitle-1" style="color: black">{{
                          waitlistFromTo.from.name
                        }}</span>
                        <v-icon class="mb-1">mdi-ray-start-arrow</v-icon>
                        <span class="subtitle-1" style="color: black">{{
                          waitlistFromTo.to.name
                        }}</span>
                        <v-card
                          flat
                          outlined
                          style="padding: 10px; margin-bottom: 10px"
                          v-for="(time, j) in waitlistFromTo.times"
                          :key="j"
                          v-if="time.waitlists.length > 0"
                        >
                          <div style="margin-bottom: 5px">
                            {{ time.time }}
                            <AddTripForWaitlist
                              :boats="boats"
                              :from="waitlistFromTo.from"
                              :to="waitlistFromTo.to"
                              :date="date"
                              :computedDate="computedDate"
                              :preferredTime="time.time"
                              :message="message"
                              :errorMessage="errorMessage"
                              :getTrips="getTrips"
                              :getWaitlists="getWaitlists"
                              :configs="configs"
                            />
                          </div>
                          <v-card
                            flat
                            color="grey lighten-4"
                            class="mb-2"
                            ripple
                            v-for="waitlist in time.waitlists"
                            :key="waitlist.id"
                          >
                            <v-card-text class="pt-4">
                              <v-row align="center">
                                <v-col class="shrink" style="padding: 0px">
                                  <v-chip
                                    v-if="waitlist.tripCreated"
                                    class="ma-2"
                                    color="blue"
                                    >Created</v-chip
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col style="padding: 2px">
                                  <div class="subtitle-3" v-if="waitlist.email">
                                    {{ waitlist.email }}
                                  </div>
                                  <div class="subtitle-3" v-if="waitlist.phone">
                                    {{ waitlist.phone }}
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-card>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-tab-item>
              <v-tab-item key="privateTransfers">
                <PrivateTransfers
                  :message="message"
                  :errorMessage="errorMessage"
                  :getImageUrl="getImageUrl"
                  :getBookings="getBookings"
                  :getTrips="getTrips"
                  :boats="boats"
                  :configs="configs"
                />
              </v-tab-item>
              <v-tab-item key="boats">
                <v-card>
                  <v-card-text>
                    <v-dialog
                      v-model="newBoatMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      width="100%"
                      max-width="500px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          outlined
                          block
                          large
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                          >Add Boat
                        </v-btn>
                      </template>
                      <v-card style="padding: 30px">
                        <v-row>
                          <v-col class="noHorizontalPadding">
                            <v-text-field
                              v-model="newBoat.name"
                              label="Boat name"
                              outlined
                            ></v-text-field>
                            <v-text-field
                              v-model="newBoat.capacity"
                              label="Capacity"
                              outlined
                              type="number"
                            >
                            </v-text-field>
                            <v-select
                              outlined
                              :items="providers"
                              label="Provider"
                              v-model="newBoat.provider"
                              item-text="name"
                              return-object
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row
                          style="
                            margin-top: -10px;
                            justify-content: space-between;
                          "
                        >
                          <div></div>
                          <v-btn
                            outlined
                            color="primary"
                            :loading="addingBoat"
                            @click="addBoat"
                            >Add Boat</v-btn
                          >
                        </v-row>
                      </v-card>
                    </v-dialog>
                    <v-progress-linear
                      v-if="boatsLoading || updatingCaptain"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-linear>
                    <v-card
                      flat
                      color="grey lighten-4"
                      class="mb-2"
                      ripple
                      v-for="(boat, index) in boats"
                      :key="boat.id"
                    >
                      <v-card-text>
                        <v-row justify="center" align="center">
                          <v-col cols="5">
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <span class="title">{{ boat.name }}</span>
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <span>
                                <v-icon small>mdi-account</v-icon>
                                {{ boat.capacity }}
                              </span>
                              <span style="margin-left: 10px">
                                <v-icon small>mdi-ship-wheel</v-icon>
                                {{ boat.provider.name }}
                              </span>
                            </div>
                            <div
                              style="display: flex; justify-content: center"
                              class="text-center"
                            >
                              <v-dialog
                                :v-model="deleteBoatDialogs[index]"
                                max-width="400"
                                :retain-focus="false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="red"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-card-text
                                    style="
                                      padding-bottom: 0px;
                                      padding-top: 20px;
                                    "
                                    >Are you sure to delete
                                    {{ boat.name }}?</v-card-text
                                  >
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="primary"
                                      text
                                      :loading="deletingBoat"
                                      @click="deleteBoat(boat)"
                                      >Delete
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-dialog
                                :v-model="editBoatDialogs[index]"
                                max-width="400"
                                :retain-focus="false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="primary"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <v-card style="padding: 30px 30px 0px 30px">
                                  <v-row>
                                    <v-col class="noHorizontalPadding">
                                      <v-text-field
                                        v-model="boat.name"
                                        label="Boat name"
                                        outlined
                                      ></v-text-field>
                                      <v-text-field
                                        v-model="boat.capacity"
                                        label="Capacity"
                                        outlined
                                        type="number"
                                      >
                                      </v-text-field>
                                      <v-select
                                        outlined
                                        :items="providers"
                                        label="Provider"
                                        v-model="boat.provider"
                                        item-text="name"
                                        return-object
                                        hide-details
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                  <v-card-actions
                                    style="
                                      margin-right: -20px;
                                      padding-right: 0px;
                                    "
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="primary"
                                      text
                                      :loading="editingBoat"
                                      @click="editBoat(boat)"
                                      >Edit
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <UpdateLayout
                                v-if="
                                  configEnabled('Enable seat selection feature')
                                "
                                :boat="boat"
                                :errorMessage="errorMessage"
                              />
                            </div>
                          </v-col>
                          <v-col cols="7">
                            <div
                              style="
                                display: flex;
                                gap: 0.3rem;
                                flex-wrap: wrap;
                                margin-bottom: 0.4rem;
                              "
                            >
                              <v-icon class="mb-1"
                                >mdi-account-cowboy-hat</v-icon
                              >
                              <v-chip
                                small
                                v-for="boatStaff in boat.boatStaff"
                                close
                                close-icon="mdi-close-outline"
                                @click:close="removeBoatStaff(boatStaff)"
                                >{{ boatStaff.user.username }}
                              </v-chip>
                            </div>
                            <v-select
                              dense
                              outlined
                              :items="
                                captains.filter(
                                  (c) =>
                                    !boat.boatStaff
                                      .map((bs) => bs.userId)
                                      .includes(c.id)
                                )
                              "
                              label="Add to boat staff"
                              item-text="username"
                              return-object
                              hide-details
                              @change="
                                (captain) => addToBoatStaff(captain, boat)
                              "
                            >
                              <template v-slot:item="{ item }">{{
                                item.username
                              }}</template>
                              <template v-slot:selection="{ item }">{{
                                item.username
                              }}</template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="providers">
                <v-card>
                  <v-card-text>
                    <v-dialog
                      v-model="newProviderMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      width="100%"
                      max-width="500px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          outlined
                          block
                          large
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                          >Add Provider
                        </v-btn>
                      </template>
                      <v-card style="padding: 30px">
                        <v-row>
                          <v-col class="noHorizontalPadding">
                            <v-text-field
                              v-model="newProvider.name"
                              label="Provider name"
                              outlined
                            ></v-text-field>
                            <v-text-field
                              v-model="newProvider.bankAccountMVR"
                              label="MVR Account Number"
                              outlined
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row
                          style="
                            margin-top: -10px;
                            justify-content: space-between;
                          "
                        >
                          <div></div>
                          <v-btn
                            outlined
                            color="primary"
                            :loading="addingProvider"
                            @click="addProvider"
                            >Add Provider
                          </v-btn>
                        </v-row>
                      </v-card>
                    </v-dialog>
                    <v-progress-linear
                      v-if="providersLoading"
                      indeterminate
                      color="primary"
                    ></v-progress-linear>
                    <v-card
                      flat
                      color="grey lighten-4"
                      class="mb-2"
                      ripple
                      v-for="(provider, index) in providers"
                      :key="provider.id"
                    >
                      <v-card-text>
                        <v-row justify="center" align="center">
                          <v-col>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <span class="title">{{ provider.name }}</span>
                            </div>
                            <div
                              v-if="provider.bankAccountMVR"
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <span>
                                <v-icon small>mdi-account</v-icon>
                                {{ provider.bankAccountMVR }}
                              </span>
                            </div>
                            <div
                              style="display: flex; justify-content: center"
                              class="text-center"
                            >
                              <v-dialog max-width="400" :retain-focus="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="red"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-card-text
                                    style="
                                      padding-bottom: 0px;
                                      padding-top: 20px;
                                    "
                                    >Are you sure to delete
                                    {{ provider.name }}?</v-card-text
                                  >
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="primary"
                                      text
                                      :loading="deletingProvider"
                                      @click="deleteProvider(provider)"
                                      >Delete</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-dialog max-width="400" :retain-focus="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="primary"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <v-card style="padding: 30px 30px 0px 30px">
                                  <v-row>
                                    <v-col class="noHorizontalPadding">
                                      <v-text-field
                                        v-model="provider.name"
                                        label="Provider name"
                                        outlined
                                      >
                                      </v-text-field>
                                      <v-text-field
                                        v-model="provider.bankAccountMVR"
                                        label="MVR Account Number"
                                        outlined
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-card-actions
                                    style="
                                      margin-right: -20px;
                                      padding-right: 0px;
                                    "
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="primary"
                                      text
                                      :loading="editingProvider"
                                      @click="editProvider(provider)"
                                      >Edit</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="users">
                <v-card>
                  <v-card-text>
                    <RegisterNewUser
                      :message="message"
                      :errorMessage="errorMessage"
                      :getUsers="getUsers"
                    />
                    <v-progress-linear
                      v-if="usersLoading || updatingUser"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-linear>
                    <v-card
                      flat
                      color="grey lighten-4"
                      class="mb-2"
                      ripple
                      v-for="user in users"
                      :key="user.id"
                    >
                      <div
                        v-if="user.isSuperUser"
                        style="
                          display: flex;
                          align-items: center;
                          margin-bottom: -15px;
                        "
                      >
                        <v-divider></v-divider>
                        <span
                          style="
                            margin-right: 10px;
                            margin-left: 10px;
                            color: rgba(0, 0, 0, 0.5);
                          "
                          >superuser</span
                        >
                        <v-divider></v-divider>
                      </div>
                      <v-card-text class="title">
                        <v-row align="center" justify="center">
                          <v-col class="d-flex justify-center">{{
                            user.username
                          }}</v-col>
                          <v-col class="noVerticalPadding">
                            <div
                              style="
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                              "
                            >
                              <div>
                                <v-switch
                                  :disabled="
                                    user.isSuperUser || !currentUser.isSuperUser
                                  "
                                  v-model="user.isAdmin"
                                  label="Admin"
                                  @change="updateUser(user)"
                                  hide-details
                                >
                                </v-switch>
                                <v-switch
                                  :disabled="user.isSuperUser"
                                  v-model="user.isCaptain"
                                  label="Captain"
                                  @change="updateUser(user)"
                                ></v-switch>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="locations">
                <v-card>
                  <v-card-text>
                    <v-dialog
                      v-model="newLocationMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      width="100%"
                      max-width="500px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          outlined
                          block
                          large
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                          >Add Location
                        </v-btn>
                      </template>
                      <v-card style="padding: 30px">
                        <v-row>
                          <v-col class="noHorizontalPadding">
                            <v-text-field
                              v-model="newLocation.name"
                              label="Location name"
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row
                          style="
                            margin-top: -10px;
                            justify-content: space-between;
                          "
                        >
                          <div></div>
                          <v-btn
                            outlined
                            color="primary"
                            :loading="addingLocation"
                            @click="addLocation"
                            >Add Location
                          </v-btn>
                        </v-row>
                      </v-card>
                    </v-dialog>
                    <v-progress-linear
                      v-if="locationsLoading"
                      indeterminate
                      color="primary"
                    ></v-progress-linear>
                    <v-card
                      flat
                      color="grey lighten-4"
                      class="mb-2"
                      ripple
                      v-for="(location, index) in locations"
                      :key="location.id"
                    >
                      <v-card-text>
                        <v-row justify="center" align="center">
                          <v-col>
                            <div class="title text-center">
                              {{ location.name }}
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                              class="text-center"
                            >
                              <v-dialog max-width="400" :retain-focus="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="red"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-card-text
                                    style="
                                      padding-bottom: 0px;
                                      padding-top: 20px;
                                    "
                                    >Are you sure to delete
                                    {{ location.name }}?</v-card-text
                                  >
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="primary"
                                      text
                                      :loading="deletingLocation"
                                      @click="deleteLocation(location)"
                                      >Delete</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-dialog max-width="400" :retain-focus="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="primary"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <v-card style="padding: 30px 30px 0px 30px">
                                  <v-row>
                                    <v-col class="noHorizontalPadding">
                                      <v-text-field
                                        v-model="location.name"
                                        label="Location name"
                                        outlined
                                        hide-details
                                      >
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-card-actions
                                    style="
                                      margin-right: -20px;
                                      padding-right: 0px;
                                    "
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="primary"
                                      text
                                      :loading="editingLocation"
                                      @click="editLocation(location)"
                                      >Edit</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <AddPoint
                                :location="location"
                                :getLocations="getLocations"
                                :message="message"
                                :errorMessage="errorMessage"
                              />
                              <v-switch
                                v-model="location.public"
                                label="Public"
                                @change="toggleLocationPublic(location)"
                                style="margin-top: 0px"
                                hide-details
                              >
                              </v-switch>
                            </div>
                            <div
                              v-if="
                                location.points && location.points.length > 0
                              "
                              class="text-center"
                              style="margin-top: 1rem"
                            >
                              <div
                                v-for="point in location.points"
                                style="margin-top: 0.5rem"
                              >
                                {{ point.name }}
                                <EditPoint
                                  :point="point"
                                  :getLocations="getLocations"
                                  :message="message"
                                  :errorMessage="errorMessage"
                                />
                                <DeletePoint
                                  :point="point"
                                  :getLocations="getLocations"
                                  :message="message"
                                  :errorMessage="errorMessage"
                                />
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="reports">
                <Reports
                  :message="message"
                  :errorMessage="errorMessage"
                  :decimalCurrency="decimalCurrency"
                />
              </v-tab-item>
              <v-tab-item key="config">
                <Config
                  :message="message"
                  :errorMessage="errorMessage"
                  :configs="configs"
                  :getConfigs="getConfigs"
                  :loadingConfigs="loadingConfigs"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import AddTripForWaitlist from "../components/AddTripForWaitlist.vue";
import UpdateLayout from "../components/Admin/Boat/UpdateLayout.vue";
import AddPoint from "../components/Admin/Point/AddPoint.vue";
import DeletePoint from "../components/Admin/Point/DeletePoint.vue";
import EditPoint from "../components/Admin/Point/EditPoint.vue";
import SeatBreakdown from "../components/Booking/SeatBreakdown.vue";
import BookingDialog from "../components/BookingDialog.vue";
import Bookings from "../components/Bookings.vue";
import ChangeBookingByAdmin from "../components/ChangeBookingByAdmin.vue";
import ChangeTripBoat from "../components/ChangeTripBoat.vue";
import Config from "../components/Config/Config.vue";
import EditTripPricing from "../components/EditTripPricing.vue";
import Nav from "../components/Nav.vue";
import PendingCashPaymentRequests from "../components/PendingCashPaymentRequests.vue";
import PendingPrivateTransfers from "../components/PendingPrivateTransfers.vue";
import PendingVerifications from "../components/PendingVerifications.vue";
import PendingWaitlists from "../components/PendingWaitlists.vue";
import PrivateTransfers from "../components/PrivateTransfers.vue";
import ProceedTransfer from "../components/ProceedTransfer.vue";
import RegisterNewUser from "../components/RegisterNewUser.vue";
import Reports from "../components/Reports.vue";
import RescheduleTrip from "../components/RescheduleTrip.vue";
import AddTrip from "../components/Trip/AddTrip.vue";
import FuelConsumption from "../components/Trip/FuelConsumption.vue";
import PaymentDetails from "../components/Trip/PaymentDetails.vue";
import TripRemarks from "../components/Trip/TripRemarks.vue";
import ViewLayout from "../components/Trip/ViewLayout.vue";
import {
  BOOKING_PAYMENT_TYPES,
  DT_FORMATS,
  configEnabled,
  decimalCurrency,
  filterBookings,
  toFromString,
} from "../helpers";

export default {
  name: "Admin",
  components: {
    Nav,
    RescheduleTrip,
    ChangeBookingByAdmin,
    AddTripForWaitlist,
    ProceedTransfer,
    PendingVerifications,
    PendingWaitlists,
    Reports,
    Bookings,
    BookingDialog,
    PendingCashPaymentRequests,
    EditTripPricing,
    PrivateTransfers,
    PendingPrivateTransfers,
    ChangeTripBoat,
    RegisterNewUser,
    Config,
    PaymentDetails,
    AddPoint,
    DeletePoint,
    EditPoint,
    AddTrip,
    FuelConsumption,
    UpdateLayout,
    ViewLayout,
    TripRemarks,
    SeatBreakdown,
  },
  data: () => ({
    currentUser: null,
    snackbar: false,
    snackbarText: "",
    errorSnackbar: false,
    errorSnackbarText: "",
    tab: "trips",
    menu: null,
    date: moment().format("YYYY-MM-DD"),
    tripsLoading: false,
    trips: [],
    selectedTrip: null,
    bookings: [],
    bookingsLoading: false,
    addTripModalLoading: false,
    locations: [],
    deleteLocationDialogs: [],
    deletingLocation: false,
    editingLocation: false,
    locationsLoading: false,
    boats: [],
    deleteBoatDialogs: [],
    editBoatDialogs: [],
    boatsLoading: false,
    updatingBoat: false,
    confirmDialog: null,
    deletingTrip: false,
    usersLoading: false,
    users: [],
    updatingUser: false,
    newBoatMenu: null,
    newBoat: {
      name: "",
      capacity: null,
      provider: null,
    },
    addingBoat: false,
    deletingBoat: false,
    editingBoat: false,
    updatingCaptain: false,
    newLocationMenu: null,
    newLocation: {
      name: "",
    },
    addingLocation: false,
    waitlists: [],
    waitlistsLoading: false,
    pendingWaitlists: [],
    newProviderMenu: null,
    newProvider: {
      name: "",
      bankAccountMVR: "",
    },
    addingProvider: false,
    providers: [],
    providersLoading: false,
    deletingProvider: false,
    editingProvider: false,
    pendingLoading: false,
    pendings: [],
    pendingCashRequests: [],
    pendingPrivateTransfers: [],
    tabMap: {
      trips: 0,
      bookings: 1,
      waitlists: 2,
      privateTransfers: 3,
      boats: 4,
      providers: 5,
      users: 6,
      locations: 7,
      reports: 8,
      config: 9,
    },
    configs: [],
    loadingConfigs: false,
    bookingSearch: "",
  }),
  computed: {
    computedDate() {
      return this.date ? moment(this.date).format("dddd, D MMMM YYYY") : "";
    },
    captains() {
      return this.users.filter((user) => user.isCaptain);
    },
    currentTrip() {
      return parseInt(this.$route.query.trip);
    },
    pickupPoints() {
      if (!this.bookings || this.bookings.length === 0) return [];
      const allPoints = [];
      for (const booking of this.bookings) {
        if (!booking.isPaid && !booking.isAllowed && !booking.isCashAllowed)
          continue;
        let point = null;
        if (booking.tripId === this.currentTrip && booking.pickupPoint) {
          point = booking.pickupPoint;
        } else if (
          booking.returnTripId === this.currentTrip &&
          booking.dropoffPoint
        ) {
          point = booking.dropoffPoint;
        }
        if (point) {
          point.count = this.occupiesSeatCount(booking);
          allPoints.push(point);
        }
      }
      return this.uniquePoints(allPoints);
    },
    dropoffPoints() {
      if (!this.bookings || this.bookings.length === 0) return [];
      const allPoints = [];
      for (const booking of this.bookings) {
        if (!booking.isPaid && !booking.isAllowed && !booking.isCashAllowed)
          continue;
        let point = null;
        if (booking.tripId === this.currentTrip && booking.dropoffPoint) {
          point = booking.dropoffPoint;
        } else if (
          booking.returnTripId === this.currentTrip &&
          booking.pickupPoint
        ) {
          point = booking.pickupPoint;
        }
        if (point) {
          point.count = this.occupiesSeatCount(booking);
          allPoints.push(point);
        }
      }
      return this.uniquePoints(allPoints);
    },
  },
  methods: {
    filterBookings(bookings) {
      let filtered = bookings;
      if (this.bookingSearch) {
        const lower = this.bookingSearch.toLocaleLowerCase();
        filtered = filtered.filter(
          (b) =>
            b.refNo?.includes(lower) ||
            b.phone?.includes(lower) ||
            b.email?.toLocaleLowerCase().includes(lower)
        );
      }
      return filtered;
    },
    downloadCsv() {
      let csv = "";
      const rows = [
        ["Report generated on", `=""${moment().format("DD-MMM-YYYY HH:mm")}""`],
        ["Trip #", this.selectedTrip.id],
        [
          "Date",
          `=""${moment(this.selectedTrip.fromTime).format("DD-MMM-YYYY")}""`,
        ],
        ["Time", `${this.toFromString(this.selectedTrip)}`],
        ["Boat", `${this.selectedTrip.boat.name}`],
        ["From", `${this.selectedTrip.fromLocation.name}`],
        ["To", `${this.selectedTrip.toLocation.name}`],
        [],
        [
          "Payment Type",
          "Booking #",
          "Phone",
          "Email",
          "Reference",
          "Customers",
          "MVR",
          "USD",
          "MVR",
          "USD",
        ],
      ];
      let mvrTotal = 0;
      let usdTotal = 0;
      for (const type of BOOKING_PAYMENT_TYPES) {
        let filtered = [];
        if (type === "Free of charge") {
          filtered = filterBookings("", this.bookings, type, true);
        } else {
          for (const currency of ["MVR", "USD"]) {
            filtered = [
              ...filtered,
              ...filterBookings(currency, this.bookings, type, true),
            ];
          }
        }
        const typeMvrTotal = filtered
          .filter((b) => b.paidInMVR)
          .reduce((a, b) => a + b.priceInLaari, 0);
        const typeUsdTotal = filtered
          .filter((b) => b.paidInUSD)
          .reduce((a, b) => a + b.priceInCent, 0);
        for (const [i, booking] of filtered.entries()) {
          mvrTotal += booking.paidInMVR ? booking.priceInLaari : 0;
          usdTotal += booking.paidInUSD ? booking.priceInCent : 0;
          if (i === 0) {
            rows.push([
              type,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              typeMvrTotal === 0 ? "" : decimalCurrency(typeMvrTotal),
              typeUsdTotal === 0 ? "" : decimalCurrency(typeUsdTotal),
            ]);
          }
          rows.push([
            "",
            booking.refNo,
            booking.phone,
            booking.email,
            booking.transfer?.refNo ?? "",
            booking.customers[0]?.name ?? "",
            booking.paidInMVR ? decimalCurrency(booking.priceInLaari) : "",
            booking.paidInUSD ? decimalCurrency(booking.priceInCent) : "",
          ]);
          if (booking.customers?.length > 1) {
            for (const [j, customer] of booking.customers.entries()) {
              if (j === 0) continue;
              rows.push(["", "", "", "", "", customer.name]);
            }
          }
        }
      }
      rows.push([
        "Total",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        decimalCurrency(mvrTotal),
        decimalCurrency(usdTotal),
      ]);
      for (const row of rows) {
        const r = `"${row.join('","')}"`;
        csv += r + "\r\n";
      }
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = `Trip #${this.selectedTrip.id} Payments`;
      anchor.click();
    },
    changeDate(diff) {
      const m = moment(this.date);
      const formatted = m.add(diff, "days").format("YYYY-MM-DD");
      this.date = formatted;
    },
    uniquePoints(points) {
      const uniquePoints = [];
      for (const point of points) {
        const uniquePointIds = uniquePoints.map((p) => p.id);
        if (uniquePointIds.includes(point.id)) {
          const current = uniquePoints.find((p) => p.id === point.id);
          current.count += point.count;
        } else {
          uniquePoints.push(point);
        }
      }
      return uniquePoints;
    },
    occupiesSeatCount(booking) {
      let count = 0;
      for (const customer of booking.customers) {
        if (customer.ageGroup.occupiesSeat) count++;
      }
      return count;
    },
    message(message) {
      this.snackbarText = message;
      this.snackbar = true;
    },
    errorMessage(message) {
      this.errorSnackbarText = message;
      this.errorSnackbar = true;
      if (message === "Token expired.") {
        this.$store.dispatch("logout");
      }
    },
    toFromString(trip) {
      return toFromString(trip, this.$store.getters.getConfigs);
    },
    formatTime(time) {
      let format = DT_FORMATS.time12hr;
      if (configEnabled("Use 24 hour clock", this.$store.getters.getConfigs)) {
        format = DT_FORMATS.time24hr;
      }
      return moment(time).format(format);
    },
    getDatePortion(time) {
      return moment(time).format("dddd, D MMMM YYYY");
    },
    updateDate(newDate) {
      this.date = newDate;
    },
    getImageUrl(booking) {
      return `${process.env.VUE_APP_BASE_URL ?? ""}/img/paymentslip/${
        booking.uuid
      }`;
    },
    goToTrip(trip) {
      const date = moment(trip.fromTime).format("YYYY-MM-DD");
      return `/admin?date=${date}&trip=${trip.id}`;
    },
    decimalCurrency(number) {
      return decimalCurrency(number);
    },
    configEnabled(name) {
      const found = this.configs.find((c) => c.name === name);
      if (found?.value === "true") return true;
      return false;
    },
    getConfigs() {
      this.loadingConfigs = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getconfigs`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const configs = resp.data.configs ?? [];
          this.configs = configs;
          if (this.newTrip) {
            configs.forEach((c) => {
              if (c.name === "Cent per seat") {
                this.newTrip.usdPerSeat = parseInt(c.value) / 100;
              } else if (c.name === "Cent per seat local") {
                this.newTrip.usdPerSeatLocal = parseInt(c.value) / 100;
              } else if (c.name === "Laari per seat") {
                this.newTrip.mvrPerSeat = parseInt(c.value) / 100;
              } else if (c.name === "Laari per seat local") {
                this.newTrip.mvrPerSeatLocal = parseInt(c.value) / 100;
              }
            });
          }
          this.$store.dispatch("configure", {
            configs,
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.loadingConfigs = false;
        });
    },
    getTrips() {
      this.tripsLoading = true;
      const date = moment(this.date).startOf("day").toISOString();
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/gettrips?date=${date}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const trips = resp.data.trips ?? [];
          this.trips = trips;
          if (this.selectedTrip) {
            trips.forEach((tr) => {
              if (tr.id === this.selectedTrip.id) {
                this.selectedTrip = tr;
              }
            });
          } else if (this.$route.query.trip) {
            trips.forEach((tr) => {
              if (tr.id === parseInt(this.$route.query.trip)) {
                this.selectedTrip = tr;
              }
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.tripsLoading = false;
        });
    },
    getWaitlists() {
      this.waitlistsLoading = true;
      const date = moment(this.date).startOf("day").toISOString();
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/getwaitlists?date=${date}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const waitlists = resp.data.waitlists;
          const uniqueFromTo = [
            ...new Set(
              waitlists.map((wl) => `${wl.fromLocation.id}-${wl.toLocation.id}`)
            ),
          ];
          const uniqueTimes = ["Morning", "Noon", "Afternoon"];
          const waitlistsBreakdown = uniqueFromTo.map((fromto) => {
            const from = this.locations.find(
              (loc) => loc.id === parseInt(fromto.split("-")[0])
            );
            const to = this.locations.find(
              (loc) => loc.id === parseInt(fromto.split("-")[1])
            );
            return {
              from,
              to,
              times: uniqueTimes.map((time) => ({
                time,
                waitlists: waitlists.filter(
                  (wl) =>
                    wl.fromLocation.id === from.id &&
                    wl.toLocation.id === to.id &&
                    wl.preferredTime === time
                ),
              })),
            };
          });
          this.waitlists = waitlistsBreakdown ?? [];
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.waitlistsLoading = false;
        });
    },
    getBookings() {
      if (this.selectedTrip === null) return;
      this.bookingsLoading = true;
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/getbookingsfortrip?tripId=${this.selectedTrip.id}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const bookings = resp.data.bookings ?? [];
          this.bookings = bookings;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.bookingsLoading = false;
        });
    },
    getBoats() {
      this.boatsLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getboats`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.boats = resp.data.boats;
          this.deleteBoatDialogs = resp.data.boats.map(() => false);
          this.editBoatDialogs = resp.data.boats.map(() => false);
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.boatsLoading = false;
        });
    },
    getLocations() {
      this.locationsLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getlocations`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const locations = resp.data.locations;
          this.locations = locations;
          if (locations.length >= 2) {
            this.newTrip.from = locations[0];
            this.newTrip.to = locations[1];
          }
          this.deleteLocationDialogs = locations.map(() => false);
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.locationsLoading = false;
        });
    },
    dateFromQuery() {
      if (this.$route.query.date) {
        const date = moment(this.$route.query.date);
        if (date.isValid()) {
          const formattedDate = date.format("YYYY-MM-DD");
          if (this.date !== formattedDate) {
            this.date = formattedDate;
            return true;
          }
        }
      }
      return false;
    },
    deleteTrip() {
      this.deletingTrip = true;
      const data = {
        tripId: this.selectedTrip.id,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/deletetrip`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.confirmDialog = false;
          this.selectedTrip = null;
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.deletingTrip = false));
    },
    getUsers() {
      this.usersLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getusers`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.users = resp.data.users;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.usersLoading = false;
        });
    },
    updateUser(user) {
      this.updatingUser = true;
      const data = {
        userId: user.id,
        isAdmin: user.isAdmin,
        isCaptain: user.isCaptain,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/updateuser`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getUsers();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.getUsers();
          this.updatingUser = false;
        });
    },
    addProvider() {
      if (this.newProvider.name.trim() === "") {
        this.errorMessage("Please enter a provider name.");
        return;
      }
      // if (!this.newProvider.bankAccountMVR) {
      //   this.errorMessage("Please enter MVR account number.")
      //   return
      // }
      const data = {
        name: this.newProvider.name.trim(),
        bankAccountMVR: this.newProvider.bankAccountMVR,
      };
      this.addingProvider = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/createprovider`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getProviders();
          this.newProviderMenu = false;
          this.newProvider.name = "";
          this.newProvider.bankAccountMVR = "";
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.addingProvider = false));
    },
    deleteProvider(provider) {
      const data = {
        providerId: provider.id,
      };
      this.deletingProvider = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/deleteprovider`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getProviders();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.deletingProvider = false));
    },
    editProvider(provider) {
      if (provider.name.trim() === "") {
        this.errorMessage("Please enter a provider name.");
        return;
      }
      const data = {
        providerId: provider.id,
        name: provider.name,
        bankAccountMVR: provider.bankAccountMVR,
      };
      this.editingProvider = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/editprovider`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.providers = [];
          this.getProviders();
          this.getBoats();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.editingProvider = false));
    },
    getProviders() {
      this.providersLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getproviders`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.providers = resp.data.providers;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.providersLoading = false;
        });
    },
    addBoat() {
      if (this.newBoat.name.trim() === "") {
        this.errorMessage("Please enter a boat name.");
        return;
      }
      if (!this.newBoat.capacity || this.newBoat.capacity === 0) {
        this.errorMessage("Please enter boat capacity.");
        return;
      }
      if (!this.newBoat.provider) {
        this.errorMessage("Please select a provider.");
        return;
      }
      const data = {
        name: this.newBoat.name.trim(),
        capacity: parseInt(this.newBoat.capacity),
        providerId: this.newBoat.provider.id,
      };
      this.addingBoat = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/createboat`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getBoats();
          this.newBoatMenu = false;
          this.newBoat.name = "";
          this.newBoat.capacity = null;
          this.newBoat.provider = null;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.addingBoat = false));
    },
    deleteBoat(boat) {
      const data = {
        boatId: boat.id,
      };
      this.deletingBoat = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/deleteboat`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getBoats();
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.deletingBoat = false));
    },
    editBoat(boat) {
      if (boat.name.trim() === "") {
        this.errorMessage("Please enter a boat name.");
        return;
      }
      if (!boat.capacity || boat.capacity === 0) {
        this.errorMessage("Please enter boat capacity.");
        return;
      }
      if (!boat.provider) {
        this.errorMessage("Please select a provider.");
        return;
      }
      const data = {
        boatId: boat.id,
        capacity: parseInt(boat.capacity),
        name: boat.name,
        providerId: boat.provider.id,
      };
      this.editingBoat = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/editboat`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.boats = [];
          this.getBoats();
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.editingBoat = false));
    },
    addToBoatStaff(captain, boat) {
      if (!captain.id || !captain.id) return;
      const data = {
        boatId: boat.id,
        userId: captain ? captain.id : 0,
      };
      this.updatingCaptain = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/addboatstaff`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getBoats();
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.updatingCaptain = false));
    },
    removeBoatStaff(boatStaff) {
      this.updatingCaptain = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/removeboatstaff`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(boatStaff),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getBoats();
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.updatingCaptain = false));
    },
    addLocation() {
      if (this.newLocation.name.trim() === "") {
        this.errorMessage("Please enter a boat name.");
        return;
      }
      const data = {
        name: this.newLocation.name.trim(),
      };
      this.addingLocation = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/createlocation`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getLocations();
          this.newLocationMenu = false;
          this.newLocation.name = "";
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.addingLocation = false));
    },
    deleteLocation(location, index) {
      const data = {
        locationId: location.id,
      };
      this.deletingLocation = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/deletelocation`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getLocations();
          this.deleteLocationDialogs[index] = false;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.deletingLocation = false));
    },
    editLocation(location) {
      if (location.name.trim() === "") {
        this.errorMessage("Please enter a location name.");
        return;
      }
      const data = {
        locationId: location.id,
        name: location.name,
      };
      this.editingLocation = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/editlocation`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.locations = [];
          this.getLocations();
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.editingLocation = false));
    },
    toggleLocationPublic(location) {
      const data = {
        locationId: location.id,
        public: location.public,
      };
      this.editingLocation = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/togglelocationpublic`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.locations = [];
          this.getLocations();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.editingLocation = false));
    },
    getPendingVerifications() {
      this.pendingLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/pendingverifications`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const pendings = resp.data.bookings;
          pendings ? (this.pendings = pendings) : (this.pendings = []);
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.pendingLoading = false;
        });
    },
    getPendingCashPaymentRequests() {
      this.pendingLoading = true;
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/getpendingcashpaymentrequests`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const pendings = resp.data.bookings;
          pendings
            ? (this.pendingCashRequests = pendings)
            : (this.pendingCashRequests = []);
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.pendingLoading = false;
        });
    },
    getPendingPrivateTransfers() {
      this.pendingLoading = true;
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/getpendingprivatetransfers`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const pendings = resp.data.privateTransfers;
          pendings
            ? (this.pendingPrivateTransfers = pendings)
            : (this.pendingPrivateTransfers = []);
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.pendingLoading = false;
        });
    },
    getPendingWaitlists() {
      this.pendingLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getpendingwaitlists`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const pendingWaitlists = resp.data.waitlists;
          pendingWaitlists
            ? (this.pendingWaitlists = pendingWaitlists)
            : (this.pendingWaitlists = []);
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.pendingLoading = false;
        });
    },
  },
  created() {
    const user = this.$store.getters.getUser;
    if (!user || !user.isAdmin) {
      this.errorMessage("Need admin rights to access '/admin' page.");
      this.$router.push("/login");
      return;
    }
    this.currentUser = user;
    this.getConfigs();
    if (!this.dateFromQuery()) {
      this.getTrips();
      this.getWaitlists();
    }
    if (this.$route.query.tab) {
      const tabInt = this.tabMap[this.$route.query.tab];
      this.tab = tabInt;
    }
    this.getBoats();
    this.getLocations();
    this.getUsers();
    this.getProviders();
    this.getPendingVerifications();
    this.getPendingWaitlists();
    this.getPendingCashPaymentRequests();
    this.getPendingPrivateTransfers();
  },
  watch: {
    date: {
      handler: function () {
        this.getTrips();
        this.getWaitlists();
        if (this.$route.query.date !== this.date) {
          this.$router.push({
            query: {
              ...this.$route.query,
              date: this.date,
            },
          });
        }
      },
    },
    selectedTrip: {
      handler: function () {
        if (this.selectedTrip) {
          this.getBookings();
          if (this.selectedTrip.id !== parseInt(this.$route.query.trip)) {
            this.$router.push({
              query: {
                ...this.$route.query,
                trip: this.selectedTrip.id,
              },
            });
          }
        } else {
          this.$router.push({
            query: { tab: this.$route.query.tab, date: this.$route.query.date },
          });
        }
      },
    },
    tab: {
      handler: function () {
        const key = Object.keys(this.tabMap).find(
          (key) => this.tabMap[key] === this.tab
        );
        if (this.$route.query.tab !== key) {
          this.$router.replace(
            {
              query: Object.assign(
                { ...this.$route.query },
                { tab: key },
                { status: null }
              ),
            },
            () => {}
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.noVerticalPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
